

/* Border Radii */
$border-radius: (
  "lg": 32px,
  "md": 24px,
  "sm": 16px,
  "xs": 12px,
  "xxs": 8px
);

/* Opacity Levels */
$opacity: (
  "100": 1,
  "80": 0.8,
  "60": 0.6,
  "40": 0.4,
  "20": 0.2,
  "0": 0
);


/* Utility Classes */
.border-radius {
  @each $name, $value in $border-radius {
    &--#{$name} {
      border-radius: $value;
    }
  }
}

.opacity {
  @each $level, $value in $opacity {
    &--#{$level} {
      opacity: $value;
    }
  }
}

/* Спейсер для паддинга или марджина */
$spacers: (
  "xs": 8px,
  "sm": 16px,
  "md": 24px,
  "lg": 32px,
  "xl": 40px
);

/* Utility Classes with margin or padding */
@mixin spacer($name, $value, $type: "margin") {
  @if $type == "margin" {
    margin: $value;
  } @else if $type == "padding" {
    padding: $value;
  }
}

.spacer-margin {
  @each $name, $value in $spacers {
    &--#{$name} {
      @include spacer($name, $value, "margin"); // Применяется марджин
    }
  }
}

.spacer-padding {
  @each $name, $value in $spacers {
    &--#{$name} {
      @include spacer($name, $value, "padding"); // Применяется паддинг
    }
  }
}