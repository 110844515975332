@use '../../base/colors.scss' as colors;
@use '../../base/typography.scss' as typography;
@use '../../base/constants.scss' as constants;

.textarea-container {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  min-height: 144px;
  box-sizing: border-box;
}

.label {
  position: absolute;
  top: 24px;  // Центрируем лейбл
  left: 16px;
  transform: translateY(-50%);
  @include typography.body-regular-16;
  color: colors.$on-surface;
  pointer-events: none;  /* Лейбл не мешает взаимодействию с полем */
  transition: top 0.2s ease, font-size 0.2s ease;
  background-color: colors.$surface;
  padding: 2px 8px 2px 8px;
  @extend .border-radius--lg;
}

/* Стили для textarea */
.textarea-field {
  width: 100%;
  min-height: 144px;
  padding: 16px;
  margin: 0;
  border: none;
  background-color: colors.$surface;
  color: colors.$on-surface;
  @include typography.body-regular-16;
  border-radius: 4px;
  outline: none;
  position: relative;
  resize: vertical; /* Даем возможность изменять размер по вертикали */
  box-sizing: border-box;

  /* Убираем границу при фокусе */
  &:focus {
    border-color: colors.$on-surface;
    box-shadow: 0 0 0 1px colors.$on-surface;
  }

  /* Уменьшаем лейбл и поднимаем его вверх, когда textarea в фокусе или заполнено */
  &:focus + .label,
  &:not(:placeholder-shown) + .label {
    top: 0;
    @include typography.body-regular-12;
  }
}

/* Убираем ошибку при вводе */
.textarea-field:focus {
  border: -1px solid colors.$on-surface;
}

/* Стили для невалидного ввода */
.textarea-field:invalid {
  border: -1px solid colors.$status-error;
  box-shadow: 0 0 0 1px colors.$status-error;
  &:not(:placeholder-shown) + .label {
    color: colors.$status-onstatus;
    background-color: colors.$status-error;
  }
}