@use './breakpoints' as breakpoints;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

// Миксин для текста с параметрами для шрифта
@mixin font-style($font-weight, $font-size) {
  font-family: 'Rubik', sans-serif;
  font-weight: $font-weight;
  font-size: $font-size;
}

  h1{
    @include font-style(700, 64px);
    @include breakpoints.respond-to("xl") {
      @include font-style(700, 40px);
    }
      @include breakpoints.respond-to("lg") {
      @include font-style(700, 32px);
    }
  }
  // Общие стили шрифтов
  
  // Body Text
  @mixin body-regular-10 {
    @include font-style(400, 10px);
  }
  
  @mixin body-regular-12 {
    @include font-style(400, 12px);
  }
  
  @mixin body-regular-14 {
    @include font-style(400, 14px);
  }
  
  @mixin body-regular-16 {
    @include font-style(400, 16px);
  }
  
  @mixin body-regular-24 {
    @include font-style(400, 24px);
  }
  
  // Заголовки (Headers)
  @mixin header-bold-32 {
    @include font-style(700, 32px);
  }
  
  @mixin header-bold-40 {
    @include font-style(700, 40px);
  }
  
  @mixin header-bold-48 {
    @include font-style(700, 48px);
  }
  
  @mixin header-bold-64 {
    @include font-style(700, 64px);
  }
  
  // Подзаголовки (Subheaders)
  @mixin subheader-medium-20 {
    @include font-style(500, 20px);
  }
  
  @mixin subheader-medium-24 {
    @include font-style(500, 24px);
  }
  
  // Тексты для интерфейсов (UI Text)
  @mixin ui-text-regular-12 {
    @include font-style(400, 12px);
  }
  
  @mixin ui-text-regular-14 {
    @include font-style(400, 14px);
  }
  
  @mixin ui-text-medium-16 {
    @include font-style(500, 16px);
  }
  
  @mixin ui-text-medium-20 {
    @include font-style(500, 20px);
  }
  
  
  @mixin large-text-regular-80 {
    @include font-style(400, 80px);
  }