.payment-history {
    padding: 20px;

    &__loading {
        font-size: 24px;
        text-align: center;
    }

    &__error {
        color: #dc3545;
        font-size: 16px;
        text-align: center;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__card {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
    }

    &__payment-hash {
        font-size: 16px;
        color: #333;
    }

    &__status {
        font-size: 14px;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;

        &--success {
            background-color: #28a745;
            color: white;
        }

        &--error {
            background-color: #dc3545;
            color: white;
        }
    }

    &__card-body {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__date, &__type {
        font-size: 14px;
        color: #555;
    }

    &__no-data {
        font-size: 16px;
        text-align: center;
        color: #6c757d;
    }
}