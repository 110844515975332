@use "../../base/colors.scss" as colors;
@use "../../base/typography.scss" as typography;
@use "../../base/constants.scss" as constants;

.item-card {
    width: calc(33% - 8px);
    background-color: colors.$surface;
    color: colors.$on-surface;
    @extend .border-radius--md;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;

    @include respond-to("xl") {
        width: calc(50% - 8px);
        flex-grow: 0;
        min-width: 226px;
    }
    @include respond-to("lg") {
        width: calc(50% - 8px);
        flex-grow: 1;
        min-width: 288px;
    }
    @include respond-to("md") {
        width: 100%;
        flex-grow: 2;
        min-width: 288px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        stroke: colors.$on-surface;
    }

    &:hover {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__img {
        height: 232px;
        min-height: 232px;
        @extend .border-radius--md;
        object-fit: cover;
        border: 1px solid #e5e5e5;
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-bottom: 4px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 8px;

        & div {
            height: 24px;
        }

        &-name {
            @include typography.ui-text-medium-16;
            color: colors.$on-surface;
            width: 100%;
        }

        &-btn {
            background-color: transparent;
            z-index: 2;
            margin: 0;
        }
    }

    &__description {
        @include typography.body-regular-14;
        color: colors.$on-surface;
    }
}