/* Colors */
$background: rgba(235, 235, 234, 1);
$on-background: rgba(33, 33, 33, 1);

$surface: rgba(255, 255, 255, 1);
$on-surface: rgba(33, 33, 33, 1);

$primary: rgba(0, 0, 0, 1);
$primary-light: rgba(39, 39, 39, 1);
$on-primary: rgba(255, 255, 255, 1);

$secondary: rgba(213, 213, 213, 1);
$secondary-light: rgba(216, 216, 216, 1);
$on-secondary: rgba(33, 33, 33, 1);

$tertiary: rgba(244, 209, 190, 1);
$tertiary-light: rgba(255, 232, 219, 1);
$on-tertiary: rgba(33, 33, 33, 1);

$accent: rgba(113, 167, 176, 1);
$accent-light: rgba(171, 206, 212, 1);

$status-error: rgba(219, 71, 71, 1);
$status-error-light: rgba(246, 134, 134, 1);
$status-notify: rgba(71, 157, 219, 1);
$status-notify-light: rgba(151, 209, 237, 1);
$status-success: rgba(50, 169, 114, 1);
$status-success-light: rgba(86, 192, 148, 1);
$status-onstatus: rgba(255, 255, 255, 1);

$sub-pro: rgba(135, 50, 169, 1);
$sub-free: rgba(219, 115, 71, 1);
$sub-starter: rgba(50, 135, 169, 1);
$sub-special: rgba(29, 34, 41, 1);

/* Effects */
$shadow-primary: 0px 8px 14px rgba(219, 115, 71, 0.4);