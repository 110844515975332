.create-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 800px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        &-row {
            display: flex;
            flex-direction: column;
            align-items: center;

            &--with-preview {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;

                @media (max-width: 768px) {
                    flex-direction: column;
                }
            }
        }

        &-error {
            color: red;
            font-size: 14px;
            margin-top: -10px;
            text-align: center;
        }

        &-actions {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            button {
                flex: 1;
            }
        }
    }

    &__image-preview {
        width: 256px;
        height: 288px;
        border-radius: 10px;
        object-fit: cover;
        margin-bottom: 15px;
    }
}