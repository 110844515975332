/* Общие стили для модалки */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Обеспечиваем, что модалка будет поверх всех элементов */
}

/* Стили контейнера модалки */
.modal-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Заголовок и подзаголовок */
.modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.modal-subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

/* Стили для кнопок */
.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 48%;
}

.modal-btn:hover {
    background-color: #0056b3; /* Темнее при наведении */
}

.modal-btn:focus {
    outline: none;
}

/* Стили для модалки с двумя кнопками */
.modal-btn:nth-child(2) {
    background-color: #dc3545; /* Красная кнопка для Cancel */
}

.modal-btn:nth-child(2):hover {
    background-color: #c82333; /* Темнее при наведении */
}