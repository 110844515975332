.toast {
    z-index: 500;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 280px;
    max-width: 400px;
    padding: 12px 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.show {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(-50%) translateY(0);
    }

    &.hide {
        opacity: 0;
        transform: translateX(-50%) translateY(30px);
    }

    &.success {
        background-color: #28a745;
    }

    &.error {
        background-color: #dc3545;
    }

    &__close {
        background: none;
        border: none;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        padding-left: 10px;
    }
}