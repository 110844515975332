@use "../../base/colors" as colors;
@use "../../base/typography" as typography;

.space-item {
    &__container {
        width: calc(25% - 8px);
        height: 80px;
        min-width: 226px;
        min-height: 80px;
        box-sizing: border-box;
        background-color: colors.$surface;
        color: colors.$on-surface;
        @extend .border-radius--md;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        cursor: pointer;
        position: relative;
        flex-grow: 1;

        @include respond-to("xl") {
            width: calc(50% - 8px);
            flex-grow: 0;
            min-width: 226px;
        }
        @include respond-to("lg") {
            width: calc(50% - 8px);
            flex-grow: 1;
            min-width: 288px;
        }
        @include respond-to("md") {
            width: 100%;
            flex-grow: 2;
            min-width: 288px;
        }

        &:hover {
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        stroke: colors.$on-surface;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__menu-button {
        z-index: 2;
    }
}