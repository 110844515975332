@use '../../base/colors' as colors;
@use '../../base/typography' as typography;
@use '../../base/constants' as constants;

.button {
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    touch-action: manipulation;
    @extend .border-radius--lg;
    @include typography.body-regular-14;
    box-sizing: border-box;
    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px; /* Управляет отступами между иконкой и текстом */
    }

    .injected-svg {
        stroke-width: 1.5px; /* Общий стиль для иконок */
    }

    &__text {
        padding: 0 8px; /* Отступ текста внутри кнопки */
    }

    /* Модификаторы */
    &--primary {
        background-color: colors.$primary;
        color: colors.$on-primary;

        &:hover {
            background-color: colors.$primary-light;
        }

        & .injected-svg {
            stroke: colors.$on-primary;
        }
    }

    &--secondary {
        background-color: colors.$secondary;
        color: colors.$on-secondary;

        &:hover {
            background-color: colors.$secondary-light;
        }

        & .injected-svg {
            stroke: colors.$on-secondary;
        }
    }

    &--tertiary {
        background-color: colors.$tertiary;
        color: colors.$on-tertiary;

        &:hover {
            background-color: colors.$tertiary-light;
        }

        & .injected-svg {
            stroke: colors.$on-tertiary;
        }
    }

    /* Размеры */
    &--small {
        height: 24px;
        min-width: 24px;
        div{height: 24px;}
        & .injected-svg {
            stroke-width: 1px;
            width: 16px;
            height: 16px;
        }
        & .radial{
            width: 24px;
            height: 24px;
        }
    }

    &--medium {
        height: 40px;
        min-width: 40px;
        div{height: 24px;}
        & .injected-svg {
            width: 24px;
            height: 24px;
        }
        & .radial{
            width: 40px;
            height: 40px;
        }
    }

    &--large {
        height: 56px;
        min-width: 56px;
        div{height: 24px;}
        & .injected-svg {
            width: 24px;
            height: 24px;
        }
        & .radial{
            width: 56px;
            height: 56px;
        }
    }

}