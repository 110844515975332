@use '../base/colors' as colors;
@use '../base/typography.scss' as typography;
@use '../base/constants.scss' as constants;

.user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0 32px 0 32px 0;
    gap: 24px;

    .logo a {
        text-decoration: none;
        color: #333; /* Замените на ваш цвет */
        &:hover {
            color: #555; /* Цвет при наведении */
        }
    }
}

.user-container {
    position: relative; /* Контекст для абсолютных элементов */
}

.user-avatar-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    // background-color: colors.$secondary;
    @extend .border-radius--lg;
    z-index: 1000;
    position: relative;
    .icon-path{
        width: 24px;
        height: 24px;
        fill:none;
        stroke-width: 1.5px;
        stroke: colors.$on-background;
    }
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ccc;
    object-fit: cover;
}

.user-info-dropdown {
    position: absolute;
    top: -16px;
    right: -16px;
    background-color: colors.$surface;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    @extend .border-radius--md;
    padding: 8px;
    z-index: 700;
    min-width: 288px;
    /* Изначально скрываем */
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    &.visible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}


.user-info{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;
    .user-name-email{
        display: flex;
        flex-direction: column;
        .user-name{@include typography.subheader-medium-20;}
        .user-email{@include typography.body-regular-14;opacity: 0.6;}
    }
}

.user-counts{   
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 0;
    margin: 0;
    div{
        display: flex;
        flex-direction: row;
        gap: 4px;
        
    }
    .count{@include typography.body-regular-14}
}
.icon-path{
    width: 16px;
    height: 16px;
}
.user-actions{
    display: flex;
    flex-direction: column;
    gap: 0px;
    button{
        width: 100%;
    }
}

.user-subscription {
    display: flex;
    flex-direction: row;
    margin: 0;
}