.spaces-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0;
    margin: 0;
}

.boxes-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0;
    margin: 0;
}

.items-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0;
    margin: 0;
}

.name-description{
    display: flex;
    flex-direction: column;
    gap: 0px;}


.space-skeleton{
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 256px;
    height: 80px;
    background-color: #f4f4f4;

    padding: 16px;
}
.box-skeleton{
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 256px;
    height: 80px;
    background-color: #f4f4f4;

    padding: 16px;
}   
.item-skeleton{
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 256px;
    height: 80px;
    background-color: #f4f4f4;
    padding: 16px;
}