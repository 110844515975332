.empty{
    text-align: center;
    margin: 0 auto;
    padding: 0 20px;
    img{
        margin: 0 auto;
        display: block;
    }
    display: flex;
    flex-direction: column;
    gap: 8px;
    
}