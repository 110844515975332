/* Breakpoints */
$breakpoints: (
  "xl-min": 1440px, // 1440 and above
  "xl-max": 1023.98px, // 1024 - 1439
  "lg-min": 1024px,  // 1024 - 1439
  "lg-max": 767.98px,  // 768 - 1023
  "md-min": 768px,  // 768 - 1023
  "md-max": 413.98px, // 414 - 767
  "sm-min": 414px,  // 414 - 767
  "m-lt": 1240px // New breakpoint added for 1240px max-width
);
/* Mixins for media queries */ 
@mixin respond-between($min, $max) { 
  $min-size: map-get($breakpoints, "#{$min}-min");
  $max-size: map-get($breakpoints, "#{$max}-max");

  @if $min-size and $max-size {
    @media (min-width: $min-size) and (max-width: $max-size) {
      @content;
    }
  }
}

/* Миксин для одного брейкпоинта */
@mixin respond-to($breakpoint) {
  $size: map-get($breakpoints, "#{$breakpoint}-max");
  @if $size {
    @media (max-width: $size) {
      @content;
    }
  }
}