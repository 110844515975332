@use "../../base/colors.scss" as colors;
@use "../../base/typography.scss" as typography;
@use "../../base/constants.scss" as constants;

.dropdown-menu {
    z-index: 500;
    position: absolute;
    right: 16px;
    background-color: colors.$surface;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    @extend .border-radius--sm;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  
    &.dropdown-up {
        bottom: 64px;

    }

    &.dropdown-down {
        bottom: auto;
        top: 100%;
        transform: translateY(0px);
    }

    &__button {
        background-color: transparent;

        &:hover {
            background-color: colors.$secondary-light;
        }
    }

    @include respond-to("xl") {
        
    }
    // Медиазапросы для разных экранов
    @include respond-to("lg") {
        position: fixed;
        bottom: 20px; // Фиксированное расстояние от низа экрана
        left: 50%;
        transform: translateX(-50%); // Центрирование по горизонтали
        width: calc(100% - 32px); // Ширина модалки
        max-width: 400px;
        touch-action: manipulation;
    }
    
    @include respond-to("md") {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 32px);
        max-width: 400px;
        touch-action: manipulation;
    }

    

}

.action-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
   
    &.hidden {
        display: none;
    }
    @include respond-to("xl") {
        display: none;
    }
    @include respond-to("lg") {
        display: block;
    }
    @include respond-to("md") {
        display: block;
    }
    @include respond-to("xs") {
        display: block;
    }
}













