@use '../base/colors' as colors;
@use '../base/typography.scss' as typography;
@use '../base/constants.scss' as constants;


.payment-methods-list{
    width: 100%;
    max-width: 408px;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    
    @include respond-to("xl") {
        width: 100%;
        flex-grow: 0;
        min-width: 226px;
    }
    @include respond-to("lg") {
        width: 100%;
        flex-grow: 1;
        min-width: 288px;
    }
    @include respond-to("md") {
        width: 100%;
        flex-grow: 2;
        min-width: 288px;
    }
}

.payment-method{
    
    width: 100%;    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--border-color);
    background-color: colors.$surface;
    color: colors.$on-surface;
    @extend .border-radius--md;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    min-height: 80px;
    padding: 16px;
    .info{ 
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
    }
    &:hover{
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
}


.active{
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid colors.$status-success;
    box-sizing: border-box;
}