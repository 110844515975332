@use '../base/colors' as colors;
@use '../base/typography.scss' as typography;
@use '../base/constants.scss' as constants;
@use '../base/breakpoints' as breakpoints;


/* Контейнер с карточками подписок */
.subscription-cards {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

/* Стили для карточки подписки */
.subscription-info {
    width: calc(50% - 16px);
    height: 560px;
    background-color: colors.$surface;
    color: colors.$on-surface;
    @extend .border-radius--md;
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    box-sizing: border-box;
    flex-grow: 2;
    .section {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    img {
        width: calc(40% - 16px);  // Используем 100% для адаптивности
        height: auto; // Высота зависит от ширины
        object-fit: cover;
        border-radius: inherit;
    }

    .header {
        @include typography.ui-text-medium-16;
    }

    .price { 
        @include typography.ui-text-medium-20;
    }

    .description {
        @include typography.body-regular-14;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        height: fit-content;
    }

    .tier-actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: end;
        height: 100%;
        justify-content: flex-end;
        margin-top: auto;
    }

    .icon-path {
        width: 24px;
        height: 24px;
        .injected-svg {
            width: 24px;
            height: 24px;
        }
    }

    &:hover {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

  
    /* Детали подписки */
    .subscription-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
    
    }


    @include respond-to("xl") {
        width: calc(40% - 16px);
        min-width: 40%;
        height: auto;
        flex-direction: column;
        img {
            width: 100%;
            height: 254px;
            object-fit: cover;
            border-radius: inherit;
        }
    }

    @include respond-to("lg") {
        width: 100%;
        height: auto;
        flex-direction: row;
        img {
            width: calc(40% - 16px);
            height: auto;
            object-fit: cover;
            border-radius: inherit;
        }
    }

    @include respond-to("md") {
        width: 100%;
        height: auto;
        flex-direction: column;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: inherit;
        }
    }

}



/* Доступные стили */
.avaliable {
    cursor: pointer;
}

.unavailable {
    cursor: not-allowed;
    opacity: 0.5;
}

.current {
    cursor: pointer;
    border: 1px solid colors.$status-success;
}