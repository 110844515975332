@use '../base/colors' as colors;
@use '../base/typography.scss' as typography;
@use '../base/constants.scss' as constants;
@use '../base/breakpoints' as breakpoints;
.search-engine {
    display: flex;
    flex-direction:column;
    gap: 16px;
    width: 100%;
    max-width: 408px;
    box-sizing: border-box;
    @include respond-to("lg") {
        width: calc(100% - 32px);
        position: fixed;
        bottom: 24px;
        left: 50%;  // Сдвигаем от левой границы на 50%
        transform: translateX(-50%);  // Сдвигаем элемент назад на 50% его ширины для центрирования
        z-index: 500;
        touch-action: manipulation; // Отключает дабл-тап зум на кнопках и инпутах
        flex-direction:column-reverse;
    }

}

.search-bar {
    position: relative;
    width: 100%;
    cursor: text;
    display: flex;
    align-items: center;
    z-index: 600;
    @extend .border-radius--lg;
    overflow: hidden;
    transition: box-shadow 0.3s ease; // Для плавности эффекта
    background-color: colors.$surface;
    border: 1px solid colors.$background;
    input {
        width: 100%;
        border: none;
        height: 40px;
        padding: 16px;
        outline: none;
        box-sizing: border-box;
        background-color: colors.$surface;

    }

}

.search-results {
    box-sizing: border-box;
    top: 32px;
    width: 100%;
    background: colors.$surface;
    @extend .border-radius--md;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 500;
    max-height: 440px;
    overflow-y: auto;
    padding: 16px;
    padding-top: 48px;
    
    max-width: 408px;
    position: fixed;
    align-self: center;
    
    @include respond-to("lg") {
        top: 0;
        padding-top: 16px;  
        position: relative;
    }
}

.results{    
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
}



.loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: colors.$on-surface;
    padding-bottom: 40px;
    padding-top: 32px;
    flex-direction: column; // или row, в зависимости от вашего макета
    gap:16px;

    div {
      background-color: colors.$background;
      min-height: 80px;
      width: 100%;
      opacity: 1; // Начальная непрозрачность
      @extend .border-radius--sm;
      &:nth-child(1) {
        opacity: 1; // Первый div имеет непрозрачность 1
      }
      &:nth-child(2) {
        opacity: 0.8; // Второй div имеет opacity 0.8
      }
      &:nth-child(3) {
        opacity: 0.6; // Третий div имеет opacity 0.6
      }
      &:nth-child(4) {
        opacity: 0.4; // Четвертый div имеет opacity 0.4
      }
      &:nth-child(5) {
        opacity: 0.2; // Пятый div имеет opacity 0.2
      }
    }
  }

.no-results {
    padding-top: 32px;
    display: flex; 
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    color: colors.$on-surface;
    padding-bottom: 40px;
    img{
        width: 200px;
    }
}

.error-message {
    padding-top: 32px;
    display: flex; 
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    color: colors.$on-surface;
    padding-bottom: 40px;
    img{
        width: 200px;
    }

}
.search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid #e5e5e5;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    text-decoration: none;
    color: colors.$on-surface;
    margin-bottom: 8px; 
    @extend .border-radius--sm;
    &:hover {
        background-color: #efefef;
    }

    // Применение стилей для названия
    strong {
        @include typography.ui-text-medium-16;
    }

    // Применение стилей для описания
    .search-item-description {
        @include typography.body-regular-10;
    }

    img {
        min-width: 80px;
        min-height: 64px;
        width: 80px;
        height: 64px;
        object-fit: cover;  // Сохраняет пропорции изображения, подгоняя под контейнер
        border-radius: 4px;
        border: 1px solid #e5e5e5;
    }
}

.search-item-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
    p{
        padding: 0;
        margin: 0;
    }
}


.search-overlay{
    display: block;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #000;
    opacity: 0.5;
    &.hidden{
        display: none;
    }
}

