@use '../base/colors' as colors;
@use '../base/typography.scss' as typography;
@use '../base/constants.scss' as constants;
@use '../base/breakpoints' as breakpoints;



.entry-page{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 16px;
    margin: 16px;
    justify-self: center;
    @include respond-to("lg") {
        flex-direction: column;
    }
    &__container{
        width: 40%;
        max-width: 512px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        @include respond-to("lg") {
            width: 100%;
            max-width: unset;
            gap: 8px;
        }
    }
}

.title{  
    align-self: flex-start;
    @include respond-to("xl") {
        align-self: flex-start;
    }
    @include respond-to("lg") {
        align-self: center;
    }}
.subtitle{  
    align-self: flex-start;
    @include respond-to("xl") {
        align-self: flex-start;
    }
    @include respond-to("lg") {
        align-self: center;
    }
}

.image{
    width: 512px;
    height: 512px;
    @include respond-to("xl") {
        width: 360px;
        height: 360px;
    }
    @include respond-to("lg") {
        width: 360px;
        height: 360px;
    }
    @include respond-to("md") {
        width: 256px;
        height: 256px;
    }
    @include respond-to("xs") {
        width: 144px;
        height: 144px;
    }
}

.button-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.base-buttons{  
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    @include respond-to("lg") {
        flex-direction: column-reverse;
        button{
            width: 100%!important;
            flex-grow: 1;
        }
        a{
            width: 100%!important;
            flex-grow: 1;
        }
    }
}

.addictional-buttons{    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
    @include respond-to("lg") {
        flex-direction: column;
        button{
            width: 100%!important;
            flex-grow: 1;
        }
        a{
            width: 100%!important;
            flex-grow: 1;
        }
    }
}