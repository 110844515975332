@use './colors' as colors; 

// * {
//     box-sizing: border-box;
//     outline: 1px solid red;
//   }
html {
    background-color: colors.$background; // Цвет фона (убедитесь, что colors.$background определен)
    margin: 0;
    padding: 0;
    transition: 0.3s;
    font-family: 'Rubik';
    overflow-x: hidden;
  }
  
  body {
    margin: 0;
    padding: 0px;
    background-color: colors.$background;
    color: colors.$on-background;
    min-width: 320px;
  }
  .locked {
    overflow: hidden;
  }

  a{
    text-decoration: none;
    color: inherit;
  }
  
  /* Основной контейнер */
  .container {
    margin: 0 auto;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px; 
    max-width: 1440px;
    min-width: 320px;
    @include respond-to("xl") {
      padding: 24px;
      padding-bottom: 80px;
    }
    @include respond-to("lg") {
      padding: 16px;
      padding-bottom: 80px;
    }
    @include respond-to("md") {
     padding: 16px;
     padding-bottom: 80px;
  }

  
  }
  
  
  .navigate{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }
  
  .go-back{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  .actions{ 
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    @include respond-to("md") {
      z-index: 10;
      position: fixed;
      bottom: 0px;
      right:0;
      padding: 24px;
      padding-bottom: 80px;
    }
    @include respond-to("xs") {
      padding: 16px;
      padding-bottom: 80px;
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    min-width: 288px;
  
  }
  .button-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    button{
      width: 100%;}
  }
  
  /* Ссылки */
  .space-link, .box-link, .item-link, .edit-link {
    color: blue;
    text-decoration: none;
    font-weight: bold;
  }