@use '../../base/colors' as colors;
@use '../../base/typography.scss' as typography;
.buttonList {
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 8px;
    @extend .border-radius--xs;
    @include typography.body-regular-14;
    box-sizing: border-box;

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0px;
    }

    &__icon {
        .injected-svg {
            stroke-width: 1.5px;
        }
    }

    &__text {
        padding: 0 8px;
    }

    &__link {
        text-decoration: none;
        display: inline-flex;
    }

    /* Модификаторы */
    &--primary {
        background-color: colors.$surface;
        color: colors.$on-surface;

        &:hover {
            background-color: colors.$primary-light;
            color: colors.$on-primary;
        }

        & .injected-svg {
            stroke: colors.$on-primary;
        }
    }

    &--secondary {
        background-color: colors.$surface;
        color: colors.$on-surface;

        &:hover {
            background-color: colors.$secondary-light;
        }

        & .injected-svg {
            stroke: colors.$on-secondary;
        }
    }

    &--tertiary {
        background-color: colors.$tertiary;
        color: colors.$on-tertiary;

        &:hover {
            background-color: colors.$tertiary-light;
        }

        & .injected-svg {
            stroke: colors.$on-tertiary;
        }
    }

    /* Размеры */
    &--small {
        height: 24px;
        min-width: 24px;
        div{height: 24px;}
        & .injected-svg {
            width: 16px;
            height: 16px;
            stroke-width: 1px;
        }
    }

    &--medium {
        height: 40px;
        min-width: 40px;
        div{height: 24px;}
        & .injected-svg {
            width: 24px;
            height: 24px;
        }
    }

    &--large {
        height: 56px;
        min-width: 56px;
        div{height: 24px;}
        & .injected-svg {
            width: 24px;
            height: 24px;
        }
    }
}